<template>
  <div class="pdf-viewer-wrapper" ref="pdfPageWrapper" v-loading="loading">
    <div class="pdf-viewer-controls">
      <el-button-group class="mr-2">
        <el-button size="small" @click="debounceZoomOut" icon="el-icon-minus"></el-button>
        <el-button size="small" @click="debounceZoomIn" icon="el-icon-plus"></el-button>
      </el-button-group>
      <span>Seiten: {{ totalPages }}</span>
    </div>
    <virtual-list
      data-key="page"
      :data-sources="pages"
      :data-component="pageComponent"
      :extra-props="{ pdfDocument: pdfDocument, scale: scale }"
      :keeps="5"
      class="pdf-list"
    />
  </div>
</template>

<script>
import { Button, ButtonGroup } from "element-ui";
import { getDocument /*GlobalWorkerOptions, version*/ } from "pdfjs-dist";
// GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${version}/build/pdf.worker.min.mjs`;
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import VirtualList from "vue-virtual-scroll-list";
import Page from "./Page.vue";

export default {
  name: "pdf-viewer",
  components: {
    VirtualList,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
  },
  props: {
    url: String,
  },
  data() {
    return {
      pageComponent: Page,
      pages: [],
      loading: false,
      pdfDocument: null,
      totalPages: 0,
      scale: null,
    };
  },
  created() {
    this.debounceZoomIn = debounce(this.zoomIn, 300);
    this.debounceZoomOut = debounce(this.zoomOut, 300);
    this.debouncePersistScale = debounce(this.persistScale, 400);
  },
  beforeDestroy() {
    this.teardown();
  },
  mounted() {
    this.init();
    // window.addEventListener("keydown", (e) => {
    //   if (e.metaKey || e.ctrlKey) {
    //     if (e.key === "=" || e.key === "+") {
    //       e.preventDefault();
    //       e.stopPropagation();
    //       const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    //       console.log("browserZoomLevel plus", browserZoomLevel);
    //       return false;
    //     } else if (e.key === "-") {
    //       e.preventDefault();
    //       e.stopPropagation();
    //       const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    //       console.log("browserZoomLevel minus", browserZoomLevel);
    //       return false;
    //     }
    //   }
    // });
  },
  methods: {
    persistScale(newScale) {
      localStorage.setItem("pdf_scale", newScale);
    },
    async init() {
      try {
        this.loading = true;
        const pdfDocument = await getDocument({
          url: this.url,
          httpHeaders: {
            Authorization: `Bearer ${this.authToken}`,
          },
        }).promise;
        this.pdfDocument = pdfDocument;
        this.totalPages = pdfDocument.numPages;
        // set initial scale
        const page = await pdfDocument.getPage(1);
        const originalViewport = page.getViewport({ scale: 1 });
        const wrapper = this.$refs.pdfPageWrapper || document.querySelector(".pdf-viewer-controls");
        const storedScale = localStorage.getItem("pdf_scale");
        if (storedScale) {
          this.scale = +storedScale;
        } else {
          const availableWidth = Math.min(710, wrapper.clientWidth - 20);
          const scaleToFitWidth = availableWidth / originalViewport.width;
          const scale = scaleToFitWidth - scaleToFitWidth * 0.1;
          this.scale = scale;
        }
        // set pages for scroll
        this.pages = Array(this.totalPages)
          .fill("")
          .map((_, idx) => ({ page: idx + 1 }));
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    teardown() {
      if (this.pdfDocument) {
        this.pdfDocument.destroy();
      }
      this.loading = false;
      this.localUrl = "";
    },
    zoomOut() {
      const newScale = this.scale - 0.2;
      if (newScale > 0) {
        this.scale -= 0.2;
      }
      this.$nextTick(() => {
        this.debouncePersistScale(this.scale);
      });
    },
    zoomIn() {
      this.scale += 0.2;
      this.$nextTick(() => {
        this.debouncePersistScale(this.scale);
      });
    },
  },
  computed: {
    ...mapGetters("account", ["authToken"]),
  },
};
</script>

<style lang="scss" scoped>
.pdf-viewer-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.pdf-viewer-controls {
  flex-shrink: 0;
  margin-bottom: 5px;
}

.pdf-list {
  flex-grow: 1;
  overflow: auto;
}
</style>
